<template>
    <div>
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-50 xl:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>
          <div class="fixed inset-0 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <component is="XMarkIcon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar for mobile -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10 py-8">
                  <div class="flex items-center justify-center">
                    <img class="rounded-full h-20 mx-auto" src="/assets/logo-rcp-dark.png" />
                  </div>
                  <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in navigation" :key="item.name">
                            <NuxtLink @click="sidebarOpen = false" :to="item.href" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                              <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                              {{ $t(item.name) }}
                            </NuxtLink>
                          </li>
                        </ul>
                      </li>
                      <li class="mt-auto">
                        <div class="text-xs font-semibold leading-6 text-gray-400 dark:text-white">{{ $t('menu-tools') }}
                          <ul role="list" class="-mx-2 space-y-1">
                            <!-- Menu Tools -->
                            <li v-for="item in tools" :key="item.name" :class="[item.name === 'menu-settings' ? ' hover:bg-gray-800 rounded-md' : '']">
                              <div v-if="item.name === 'menu-settings'">
                                <Popover>
                                  <PopoverButton :class="[item.current ? 'bg-gray-800' : ' text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                                    {{ $t(item.name) }}
                                    <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                                  </PopoverButton>
                                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                    <PopoverPanel class="absolute z-10 flex bottom-8 left-10 w-2/3">
                                      <div class="flex-auto rounded-lg bg-gray-700 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                        <NuxtLink to="/settings/account" @click="sidebarOpen = false">
                                          <div class="rounded-t-lg px-4 py-2 hover:bg-ocean-600 cursor-pointer font-semibold text-white">
                                            {{ $t('menu-settings-profile') }}
                                          </div>
                                        </NuxtLink>
                                        <NuxtLink to="/settings/notification" @click="sidebarOpen = false">
                                          <div class="rounded-b-lg px-4 py-2 hover:bg-ocean-600 cursor-pointer font-semibold text-white">
                                            {{ $t('menu-settings-notification') }}
                                          </div>
                                        </NuxtLink>
                                      </div>
                                    </PopoverPanel>
                                  </transition>
                                </Popover>
                              </div>
                              <div v-else>
                                <NuxtLink v-if="item.name === 'menu-logout'" @click.prevent="logout()" to="/login" :class="[item.current ? 'bg-gray-800 text-gray-400' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                  <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {{ $t(item.name) }}
                                </NuxtLink>
                                <NuxtLink v-else :href="item.href" :class="[item.current ? 'bg-gray-800 text-gray-400' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                  <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {{ $t(item.name) }}
                                </NuxtLink>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
        <!-- Sidebar Computer -->
      <div class="hidden fixed h-screen xl:z-50 xl:flex xl:w-72">
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/5 py-8">
          <div class="flex items-center justify-center">
            <img class="rounded-full h-20 mx-auto" src="/assets/logo-rcp-dark.png" />
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li v-for="item in navigation" :key="item.name">
                    <NuxtLink :to="localPath(item.href)" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                        <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                        {{ $t(item.name) }}
                    </NuxtLink>
                  </li>
                </ul>
              </li>
              <!-- Menu Tools -->
              <li class="mt-auto">
                <div class="text-xs font-semibold leading-6 text-gray-400">{{ $t('menu-tools') }}
                  <ul role="list" class="-mx-2 space-y-1">
                    <li v-for="item in tools" :key="item.name" :class="[item.name === 'menu-settings' ? 'hover:text-white hover:bg-gray-800 rounded-md' : '']">
                      <div v-if="item.name === 'menu-settings'">
                        <Popover>
                          <PopoverButton :class="[item.current ? 'bg-gray-800' : ' text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ $t(item.name) }}
                            <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                          </PopoverButton>
                          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                            <PopoverPanel class="absolute z-10 flex bottom-8 left-10 w-2/3">
                              <div class="flex-auto rounded-lg bg-gray-700 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                <NuxtLink to="/settings/account">
                                  <div class="rounded-t-lg px-4 py-2 hover:bg-ocean-600 cursor-pointer font-semibold text-white">
                                      {{ $t('menu-settings-profile') }}
                                  </div>
                                </NuxtLink>
                                <NuxtLink to="/settings/notification" class="font-semibold text-white">
                                  <div class="rounded-b-lg px-4 py-2 hover:bg-ocean-600 cursor-pointer font-semibold text-white">
                                      {{ $t('menu-settings-notification') }}
                                  </div>
                                </NuxtLink>
                              </div>
                            </PopoverPanel>
                          </transition>
                        </Popover>
                      </div>
                      <div v-else>
                        <NuxtLink v-if="item.name === 'menu-logout'" @click.prevent="logout()" to="/login" :class="[item.current ? 'bg-gray-800 text-gray-400' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                          <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                          {{ $t(item.name) }}
                        </NuxtLink>
                        <NuxtLink v-else :href="item.href" :class="[item.current ? 'bg-gray-800 text-gray-400' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                          <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                          {{ $t(item.name) }}
                        </NuxtLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    <!-- HEADER -->
      <div class="fixed xl:pl-72 dark:bg-gray-900 w-full top-0 z-40 flex items-center gap-x-6 lg:bg-gray-1 px-4 right-0 py-4 bg-white">
        <div class="xl:hidden">
          <button type="button" class="-m-2.5 p-2.5 text-white-400" @click="sidebarOpen = true"  >
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="flex-1 text-sm font-semibold leading-6 text-black dark:text-white xl:ml-12"> {{ pageName }}</div>
            <Listbox v-model="language">
              <div class="relative sm:mt-2">
                <ListboxButton @click="toggleLanguageMenu" class="dark:ring-neutral-600 dark:bg-gray-900 cursor-pointer bg-white w-full text-left text-gray-900 shadow-sm  sm:text-sm sm:leading-6">
                  <div class="rounded-md bg-white px-3 py-1.5 dark:bg-gray-800">
                    <div class="flex items-center">
                      <div v-if="language === 'en'">
                        <img class="h-6 w-6 rounded-full" src="/assets/usa-flag.webp" alt="" />
                      </div>
                      <div v-else>
                        <img class="h-6 w-6 rounded-full" src="/assets/france-flag.webp" alt="" />
                      </div>
                      <span class="hidden sm:block dark:text-white ml-3 truncate">{{ language === 'en' ? t('langue_anglais') : t('langue_francais') }}</span>
                    </div>
                  </div>
                </ListboxButton>
                <!-- Options de langue -->
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                <ListboxOptions v-if="languageMenuOpen" class="dark:bg-gray-800 rounded-lg mt-2 absolute z-10 w-full bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption value="en" class="cursor-pointer hover:bg-ocean-600 flex items-center py-3 rounded-t-lg" @click="changeLanguage('en')">
                    <img src="/assets/usa-flag.webp" alt="" class="h-5 w-5 rounded-full ml-2" />
                    <span class="hidden ml-3 sm:block truncate dark:text-white">{{ t('langue_anglais') }}</span>
                  </ListboxOption>
                  <ListboxOption value="fr" class="cursor-pointer hover:bg-ocean-600 flex items-center py-3 rounded-b-lg" @click="changeLanguage('fr')">
                    <img src="/assets/france-flag.webp" alt="" class="h-5 w-5 rounded-full ml-2" />
                    <span class="hidden ml-3 sm:block truncate dark:text-white">{{ t('langue_francais') }}</span>
                  </ListboxOption>
                </ListboxOptions>

                </transition>
              </div>
            </Listbox>

            <button @click="setColorTheme(theme == 'dark' ? 'light' : 'dark')">
              <span class="sr-only">Darkmode</span>
              <svg v-if="theme === 'dark'" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
              </svg>
            </button>
            <NuxtLink to="/settings/account">
              <div v-if="theme === 'dark'">
                <img class="h-8 w-8 rounded-full bg-white-800" src="/assets/anonymous-white.png" />
              </div>
              <div v-else>
                <img class="h-8 w-8 rounded-full bg-white-800" src="/assets/anonymous-black.png" />
              </div>
            </NuxtLink>
      </div>
  </div>
</template>
<script setup lang="ts">
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    import { ChevronDownIcon } from '@heroicons/vue/20/solid'
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { ref } from 'vue'
    import {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
      } from '@headlessui/vue'
    import {
        QuestionMarkCircleIcon,
        ArrowLeftStartOnRectangleIcon,
        XMarkIcon,
        CogIcon,
        WindowIcon,
        MapIcon,
        BanknotesIcon,
        FolderOpenIcon
    } from '@heroicons/vue/24/outline'
    import { Bars3Icon } from '@heroicons/vue/20/solid'
    import { useRoute } from 'vue-router';
    import Cookies from 'js-cookie';

    const router = useRouter();
    const {t, locale, setLocale } = useI18n();
    const language = ref<string>(locale.value);
    const languageMenuOpen = ref(false);
    const localPath = useLocalePath();



    const changeLanguage = (lang: string) => {
      language.value = lang;
      setLocale(lang);
      languageMenuOpen.value = false;
    }

    const toggleLanguageMenu = () => {
      languageMenuOpen.value = !languageMenuOpen.value;
    };

    const route = useRoute();
    const currentPath = route.path;
    const page = currentPath.substring(1);
    let pageName = page.charAt(0).toUpperCase() + page.slice(1);

    const navigation = [
      { name: 'menu-dashboard', href: '/dashboard', icon: WindowIcon, current: false },
      { name: 'menu-recolte', href: '/collectPoint', icon: MapIcon, current: false },
      { name: 'menu-gains', href: '/gains', icon: BanknotesIcon, current: false },
      { name: 'menu-recuperateur', href: '/recupBac', icon: FolderOpenIcon, current: false },
    ]

    const tools = [
      { name: 'menu-settings', icon: CogIcon, current: false },
      { name: 'menu-contact', href: '/contact', icon: QuestionMarkCircleIcon, current: false },
      { name: 'menu-logout', icon: ArrowLeftStartOnRectangleIcon, current: false},
    ];


    const updateCurrentPage = (currentUrl: string) => {
      navigation.forEach(item => {
        item.current = item.href === currentUrl;
      });
      tools.forEach(item => {
      item.current = item.href === currentUrl;
    });
    }

    watch(() => route.path, (newPath) => {
      updateCurrentPage(newPath);
      const page = newPath.substring(1);
      pageName = page.charAt(0).toUpperCase() + page.slice(1);
    }, { immediate: true });


    const logout = () => {
      Cookies.remove('token');
      router.push({ name: 'login' });
    };

    const sidebarOpen = ref(false)
    const theme = useCookie('theme');
    const setColorTheme = (newTheme : string) => {
      useColorMode().preference = newTheme
      theme.value = newTheme;
    }
</script>

<template>
  <body class="flex flex-col h-screen dark:bg-gray-800">
    <nav class="xl:flex xl:flex-col xl:w-72">
      <client-only>
        <Header />
      </client-only>
    </nav>
    <main class="flex justify-center items-center-start flex-1">
      <div class="w-screen xl:ml-72 sm:h-[80vh] my-auto mx-auto pt-20 sm:pt-8">
        <client-only>
          <slot />
        </client-only>
      </div>
    </main>
  </body>
</template>

<style>
/* img, button, input:focus-visible {
    outline: none;
} */
</style>
